import React from 'react'

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({fill}) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 226.99 24.44"
      height="24px"
    >
      <path
        fill={fill}
        transform="translate(-1.23 -0.56)"
        d="M15.38,19c-.48,2.55-2.29,4.42-5.85,4.42C-.32,23.41-.75,6.59,9.42,6.32c5.51-.15,6.35,4.62,5.22,6.06-1.37,1.76-3.8.11-2.66-1.46.41-.57.69-.45.68-1.22C12.63,8.1,11.4,7,9.47,7,2.59,7,3,22.52,9.62,22.65a4.56,4.56,0,0,0,4.93-3.81Z"
      />
      <path
        fill={fill}
        transform="translate(-1.23 -0.56)"
        d="M32.73,23H29v-1c-3.53,2.39-9.42,1.09-9.06-3.83.46-6.1,9-2.3,9.06-7.29.05-2.29-1.34-3.84-3.1-3.78-3.46.13-3.37,2.7-2.51,3.15A1.34,1.34,0,0,1,23,12.83c-1.86.35-2.5-.95-2.6-2.17.31-5.07,11.16-5.73,11.16.43v9.85S31.76,23,32.73,23ZM29,21.27V13.53a4.3,4.3,0,0,1-2.69,1.33C19.58,16.14,22.85,26.09,29,21.27Z"
      />
      <path
        fill={fill}
        transform="translate(-1.23 -0.56)"
        d="M46,11.63A1.6,1.6,0,0,1,44.52,8.9a.93.93,0,0,0-.25-1.49C42.81,7,40.74,9,40.55,12.08v1.24h0V21s.2,2.05,1,2.05h-4.3c.8,0,1-1.82,1-2V9.15s-.2-2-1-2h3.27v2a4.31,4.31,0,0,1,4.51-2.18C47.31,7.53,48.31,11,46,11.63Z"
      />
      <path
        fill={fill}
        transform="translate(-1.23 -0.56)"
        d="M55.81,23H51.88c.8,0,.8-1.8.8-1.8V9s0-1.8-.8-1.8h3.93C55,7.21,55,9,55,9V21.22S55,23,55.81,23ZM52.38,3.48a1.47,1.47,0,1,1,1.47,1.46A1.47,1.47,0,0,1,52.38,3.48Z"
      />
      <path
        fill={fill}
        transform="translate(-1.23 -0.56)"
        d="M71,20c-1.48,4.37-9.3,4.4-10.47-.48-1-4,1.74-4.51,2.46-3.45a1.42,1.42,0,0,1-.38,2c-.49.32-.75.7-.6,1.73.5,3.3,7,4.19,7.31-.4.21-2.94-4.42-3.15-7.19-6.49s1.31-7.55,6-5.82c2,.74,3.06,2.55,2.18,3.56a1.18,1.18,0,0,1-2-.51c-.1-.69.34-1.07,0-1.7C66.66,5.58,61.71,8,63.75,11,65.52,13.61,73,14.2,71,20Z"
      />
      <path
        fill={fill}
        transform="translate(-1.23 -0.56)"
        d="M86.58,20c-1.47,4.37-9.3,4.4-10.47-.48-1-4,1.74-4.51,2.46-3.45a1.42,1.42,0,0,1-.38,2c-.49.32-.75.7-.6,1.73.5,3.3,7,4.19,7.31-.4.21-2.94-4.42-3.15-7.19-6.49s1.31-7.55,6-5.82c2,.74,3.06,2.55,2.19,3.56a1.19,1.19,0,0,1-2.05-.51c-.1-.69.34-1.07,0-1.7C82.24,5.58,77.29,8,79.33,11,81.1,13.61,88.54,14.2,86.58,20Z"
      />
      <path
        fill={fill}
        transform="translate(-1.23 -0.56)"
        d="M104.3,23h-3.74v-1c-3.53,2.39-9.42,1.09-9.06-3.83.46-6.1,9-2.3,9.06-7.29,0-2.29-1.34-3.84-3.1-3.78C94,7.28,94.09,9.85,95,10.3a1.34,1.34,0,0,1-.42,2.53c-1.86.35-2.51-.95-2.6-2.17.31-5.07,11.16-5.73,11.16.43v9.85S103.32,23,104.3,23Zm-3.74-1.75V13.53a4.33,4.33,0,0,1-2.7,1.33C91.15,16.14,94.42,26.09,100.56,21.27Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M132.51,19c-.48,2.55-2.29,4.42-5.85,4.42-9.84,0-10.27-16.82-.11-17.09,5.51-.15,6.35,4.62,5.23,6.06-1.38,1.76-3.8.11-2.67-1.46.42-.57.69-.45.68-1.22,0-1.6-1.25-2.71-3.19-2.71-6.88,0-6.44,15.53.16,15.66a4.56,4.56,0,0,0,4.92-3.81Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M149.87,23h-3.73v-1c-3.54,2.39-9.43,1.09-9.06-3.83.45-6.1,9-2.3,9.06-7.29.05-2.29-1.35-3.84-3.1-3.78-3.46.13-3.38,2.7-2.52,3.15a1.33,1.33,0,0,1-.42,2.53c-1.86.35-2.51-.95-2.6-2.17.31-5.07,11.16-5.73,11.16.43v9.85S148.89,23,149.87,23Zm-3.74-1.75V13.53a4.33,4.33,0,0,1-2.7,1.33C136.72,16.14,140,26.09,146.13,21.27Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M168.06,23h-4.51c.81,0,1-1.24,1.06-1.7,0-1.89,0-8.89,0-8.89,0-6.07-5.6-5.91-7.26-1.37V21.32s0,1.7,1.08,1.7H154c.94,0,1.06-1.29,1.07-1.62V9.23c0-.66-.41-2.09-1.08-2.09h3.39V9.4c2-3.73,9.61-2.91,9.61,3,0,1.88,0,8.67,0,8.94C167,21.82,167,23,168.06,23Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M183.4,23V21.21A5.9,5.9,0,0,1,179.14,23c-3.85,0-6.54-3.41-6.54-8s2.69-8.24,6.54-8.24a5.77,5.77,0,0,1,4.26,1.89V3.61c-.1-.57-.4-1.8-1.06-1.8h3.33V20.92s.21,2.1,1.07,2.1Zm0-5.74V11.81c-.5-3-2.29-4.41-4.27-4.41-2.37,0-3.86,3.62-3.86,7.48s1.49,7.46,3.86,7.46S183.41,20.38,183.41,17.28Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M203.23,14.48H194c0,.15,0,.3,0,.45,0,3.85,1.68,7.57,4.81,7.57s4.79-2.33,4.65-3.78c-.07-.75-.55-.61-1-1.24-1.09-1.66,1.72-3.15,2.56-1.17s-.69,6.86-6.5,6.86c-4.25,0-7.23-3.69-7.23-8.24s3-8.23,7.23-8.23,7,3.23,7,7.78Zm-9.2-.91h9.15c-.3-3.45-2.28-6.28-4.68-6.28S194.42,10.24,194,13.57Z"
      />
      <path
        fill="#b3b3b3"
        transform="translate(-1.23 -0.56)"
        d="M222,14.48h-9.26c0,.15,0,.3,0,.45,0,3.85,1.68,7.57,4.81,7.57s4.79-2.33,4.65-3.78c-.07-.75-.55-.61-1-1.24-1.09-1.66,1.72-3.15,2.56-1.17s-.69,6.86-6.5,6.86c-4.25,0-7.23-3.69-7.23-8.24s3-8.23,7.23-8.23,7,3.23,7,7.78Zm-9.2-.91H222c-.3-3.45-2.28-6.28-4.68-6.28S213.21,10.24,212.82,13.57Z"
      />
    </svg>
  )
}
